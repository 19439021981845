<template>
  <!-- <div class="container mt-1"> -->
    <div class="card mt-1">
      <div class="card-body h-100">
        <div class="row">
          <div class="col-xl-12">
            <h2 class="font-weight-bolder">แก้ไขโปรไฟล์</h2>
            <hr />
            <div class="row mb-1">
              <div class="col-12 col-sm-8">
                <validation-observer ref="editprofileForm">
                  <b-form class="auth-edit-form" @submit.prevent>
                    <validation-provider #default="{ errors }" rules="required">
                      <b-form-group
                        label-for="Email"
                        label="ชื่อผู้ใช้"
                        label-cols-md="4"
                      >
                        <b-form-input
                          v-model="username"
                          class="form-control"
                          :state="errors.length > 0 ? false : null"
                          disabled
                        />
                        <small v-if="errors.length > 0" class="text-danger"
                          >กรุณากรอกชื่อผู้ใช้</small
                        >
                      </b-form-group>
                    </validation-provider>
                    <validation-provider #default="{ errors }" rules="required">
                      <b-form-group label="ชื่อจริง" label-cols-md="4">
                        <b-form-input
                          v-model="firstName"
                          class="form-control"
                          :state="errors.length > 0 ? false : null"
                          autofocus
                        />
                        <small v-if="errors.length > 0" class="text-danger"
                          >กรุณากรอกชื่อจริง</small
                        >
                      </b-form-group>
                    </validation-provider>
                    <validation-provider #default="{ errors }" rules="required">
                      <b-form-group label="นามสกุล" label-cols-md="4">
                        <b-form-input
                          v-model="lastName"
                          class="form-control"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small v-if="errors.length > 0" class="text-danger"
                        >กรุณากรอกนามสกุล</small
                      >
                      </b-form-group>
                      
                    </validation-provider>
                    <validation-provider
                      #default="{ errors }"
                      name="bankName"
                      rules="required"
                    >
                      <b-form-group label="ธนาคาร" label-cols-md="4">
                        <b-form-select
                          v-model="bankaccountName"
                          :options="optionBank"
                          :state="errors.length > 0 ? false : null"
                          class="form-control"
                        />
                        <small v-if="errors.length > 0" class="text-danger"
                          >โปรดระบุบัญชีธนาคาร</small
                        >
                      </b-form-group>
                    </validation-provider>
                    <validation-provider #default="{ errors }" rules="required">
                      <b-form-group label="เลขบัญชีธนาคาร" label-cols-md="4">
                        <b-form-input
                          v-model="bankaccountNumber"
                          :state="errors.length > 0 ? false : null"
                          class="form-control"
                        />
                        <small v-if="errors.length > 0" class="text-danger"
                        >กรุณากรอกเลขบัญชีธนาคาร</small
                      >
                      </b-form-group>
                      
                    </validation-provider>
                  </b-form>
                </validation-observer>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-center mt-2">
              <b-button variant="success" size="md" @click="editProfile"
                >ยืนยัน</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- </div> -->
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BModal,
  BButton,
  VBModal,
  BCardBody,
  BFormInput,
  BForm,
  BInputGroupAppend,
  BInputGroup,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
// import DatePicker from '../components/datePicker.vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BModal,
    BButton,
    VBModal,
    BCardBody,
    BFormInput,
    BForm,
    BInputGroupAppend,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      bankaccountNumber: "",
      bankaccountName: null,
      username: "",
      firstName: "",
      lastName: "",
      optionBank: [
        {
          value: null,
          text: "เลือกธนาคาร",
        },
        {
          value: "SCB",
          text: "ธนาคารไทยพาณิชย์",
        },
        {
          value: "KBANK",
          text: "ธนาคารกสิกร",
        },
        {
          value: "KTB",
          text: "ธนาคารกรุงไทย",
        },
        {
          value: "GSB",
          text: "ธนาคารออมสิน",
        },
        {
          value: "TMB",
          text: "ธนาคารทหารไทย",
        },
        {
          value: "BBL",
          text: "ธนาคารกรุงเทพ",
        },
      ],
      required,
    };
  },
  created() {
    this.LoadData();
  },
  methods: {
    LoadData() {
      const userData = JSON.parse(localStorage.getItem("userData"));
      this.$store
        .dispatch("user/getProfile", { id: userData.id })
        .then((result) => {
          console.log(result)
          this.username = result.item.username;
          this.bankaccountNumber = result.item.bankaccountNumber;
          this.bankaccountName = result.item.bankaccountName;
          this.firstName = result.item.firstName;
          this.lastName = result.item.lastName;
          this.$store.commit('game/SET_LOADING',false)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editProfile() {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const payload = {
        id: userData.id,
        bankaccountNumber: this.bankaccountNumber,
        bankaccountName: this.bankaccountName,
        firstName: this.firstName,
        lastName: this.lastName,
      };
      console.log(payload);
      this.$store
        .dispatch("user/editProfile", payload)
        .then((result) => {
          if (result.success) {
            localStorage.removeItem("userData");
            const newUserData = {
              id: userData.id,
              firstName: this.firstName,
              lastName: this.lastName,
              username: this.username,
              ability: [
                {
                  action: "manage",
                  subject: "all",
                },
              ],
            };
            localStorage.setItem("userData", JSON.stringify(newUserData));
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "โปรไฟล์",
                icon: "UserIcon",
                variant: "success",
                text: result.message,
              },
            });

            // this.$store.commit("auth/setUserData", newUserData);
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "โปรไฟล์",
                icon: "UserIcon",
                variant: "danger",
                text: result.message,
              },
            });
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "โปรไฟล์",
              icon: "UserIcon",
              variant: "danger",
              text: error.message,
            },
          });
        });
    },
  },
};
</script>

//
<style lang="scss" scoped>
// .line {
//     border-width:1px;
//     border-style:dashed;
//     border-color: #77ad82;
// }

// .suggest {
//     color:#9a9a9a; font-size:12px;
// }
</style>
